<template>
    <div class="row">
      <div class="col-12">
        <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
          <div class="iq-card-body">
            <div class="image-block">
              <img :src="quizDetail.app_image" class="img-fluid rounded m-auto d-block" alt="blog-img">
            </div>
            <div class="blog-description mt-2">
              <h5 class="mb-1 border-bottom quiz_detail_heading">
                  {{quizDetail.app_name}}
                <span class="three_multi_buttons">
                  <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="pull-right mr-2" variant="primary" size="sm" @click="goToUrl(`/quizBank_list/${quizDetail.app_id}`)">Question Bank</b-btn>
                  <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="pull-right mr-2" variant="primary" size="sm" @click="goToUrl(`/quiz_edit/${quizDetail.app_id}`)">Edit</b-btn>
                  <b-btn class="pull-right mr-2" variant="primary" size="sm" @click="startQuiz(quizDetail.app_id)">Start Assessment</b-btn>
                </span>
                <b-modal v-model="showModelQuizDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
                  <p>{{cvDelMessage}}</p>
                  <template #modal-footer="">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button size="sm" @click="showModelQuizDelete = false">
                      {{cvbtnModalCancel}}
                    </b-button>
                    <b-button size="sm" variant="primary" @click="appDelete()">
                      {{cvbtnModalDelOk}}
                    </b-button>
                  </template>
                </b-modal>
              </h5>
              <div class="" v-if="quizDetail.has_timer">
                <span class="mr-2">Quiz Duration:</span>
                <b-badge
                  class="border mr-2 mb-2 font-weight-normal"
                  variant="none">
                  <span>
                    {{quizDetail.app_duration | durationToDHM}}
                  </span>
                </b-badge>
              </div>
              <p class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description"></p>
            </div>
          </div>
        </div>
      </div>

      <b-toast
        v-model="showToast"
        name="Toast"
        :variant="toastVariant"
        :title="toastTitle">
        <div v-html="toastMsg">
        </div>
      </b-toast>

      <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc"
      :propOkBtnText="okText" :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToUrl(quizDetail.module_payment_page)"/>
    </div>
  </template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility.js"
import { Apps } from "../../../FackApi/api/apps.js"
import Config from "../../../Utils/config.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import moment from "moment"
import GPathData from "../../../FackApi/json/GPath.json"
import GideProducts from "../../../FackApi/json/GideProducts.json"

export default {
  name: "GPathQuizDetail",
  components: {
    AlertBox
  },
  data () {
    return {
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      quizId: null,
      quizDetail: {},
      toastVariant: "",
      showToast: false,
      cvDelMessage: "Are You Sure?",
      cvbtnModalCancel: "Cancel",
      cvbtnModalDelOk: "Delete",
      delObj: {},
      toastMsg: "",
      showModelQuizDelete: false,
      toastTitle: "Quiz",
      showUpgradeAlertBox: false,
      alertDesc: "This is a paid activity. Please register and complete your payment to activate this activity",
      alertTitle: "Quiz",
      okText: "Register",
      cancelText: "Not now",
      utility: Utility,
      GideProducts: GideProducts,
      currentTs: moment().utc().valueOf() / 1000,
      examList: [],
      gpathPlanModuleObjId: GideProducts.GPATHS,
      Config: Config,
      gide_right_mobile_data: true,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList()
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    gpathSession () {
      return this.$store.getters["GpathSession/gpathSession"]
    }
  },
  props: {
    propAppId: {
      type: String,
      default: null
    },
    propParentComponentName: {
      type: String,
      default: null
    }
  },
  watch: {
    "$route.params.quizId": {
      handler: function (ev) {
        this.loadRequiredData()
      }
    }
  },
  mounted () {
    if (navigator.userAgent.toLowerCase().includes("iphone") ||
        navigator.userAgent.toLowerCase().includes("android")
    ) {
      this.gide_right_mobile_data = false
    }

    this.loadRequiredData()
  },
  methods: {
    /**
       * loadRequiredData
       */
    async loadRequiredData () {
      socialvue.index()
      this.quizId = this.$route.params.quizId
      if (!this.quizId) {
        this.quizId = this.propAppId
      }
      this.appView()
    },
    /**
       * getExamList
      */
    async getExamList (type) {
      let payload = {
        filter: JSON.stringify({ limit: 5, type: type }),
        upcoming: 1
      }
      const quizListResp = await Apps.appList(this, payload)
      if (!quizListResp.resp_status) {
        return
      }
      this.examList = quizListResp.resp_data.data
    },
    /**
       * goToUrl
       */
    goToUrl (url) {
      if (this.quizDetail.app_type == Config.QUIZ_TYPE.competition) {
        this.$router.push(`/event/${this.quizDetail.app_id}`)
      }
      else if (url && url.length) {
        window.open(url, "_blank")
      }
    },
    /**
       * appDelete
       */
    async appDelete () {
      try {
        let quizDelResp = await Apps.appDelete(this, this.delObj.app_id)
        await ApiResponse.responseMessageDisplay(this, quizDelResp)

        if (quizDelResp && !quizDelResp) {
          this.showModelQuizDelete = false
          return false
        }
        this.showModelQuizDelete = false
        this.quizDetail.deleted = 1
      }
      catch (err) {
        console.error("Exception occurred at appDelete() and Exception:", err.message)
      }
    },
    /**
       * Quiz View
       */
    async appView () {
      const quizResp = await Apps.appView(this, this.quizId)
      if (!quizResp.resp_status || !quizResp.resp_data.data.app_id) {
        this.$router.push(`/gpath`)
      }

      this.quizDetail = quizResp.resp_data.data
      if (!this.isMobileDevice) {
        this.getExamList(this.quizDetail.app_type)
      }
    },
    /**
       * showQuizDeleteDialog
       */
    showQuizDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelQuizDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showQuizDeleteDialog() and Exception:", err.message)
      }
    },
    /**
       * QuizDetail
      */
    async QuizDetail (quizId) {
      this.$router.push(`/quiz_detail/${quizId}`)
    },
    /**
       * appResultList
       */
    appResultList (app) {
      this.$router.push(`/appResult_list/${app.app_id}`)
    },
    /**
       * startQuiz
      */
    async startQuiz (examId) {
      // If  propAppId and  is passed into, it is used in Gpaths workflow
      if (this.propAppId && this.propParentComponentName == "GPathWorkflow") {
        this.validateUserGpathSessionState()
        return
      }

      if (this.quizDetail.app_type === Config.QUIZ_TYPE.competition && this.quizDetail.user_registered !== 1 && this.quizDetail.app_is_paid === 1) {
        this.showUpgradeAlertBox = true
      }
      else {
        this.$router.push(`/question_quiz/${examId}`)
      }
    },
    /**
       * validateUserGpathSessionState
       */
    async validateUserGpathSessionState () {
      let userPaymentStatus = this.gpathSession.pay_tid || this.gpathSession.aff_code

      if (userPaymentStatus) {
        if (this.propAppId.includes(GPathData.GPATH_CAREER) && this.gpathSession.session_state > GPathData.GPATH_STATE_CAREER) {
          // User has already played career quiz
          this.$emit("emitStartGpathQuiz", false, "You have already taken this assessment", "Career Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_CAREER) && this.gpathSession.session_state < GPathData.GPATH_STATE_PAID) {
          // User has not made the payment
          this.$emit("emitStartGpathQuiz", false, "Please complete the previous steps", "Incomplete Information")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_SKILL) && this.gpathSession.session_state >= GPathData.GPATH_STATE_SKILL) {
          // User has already played skill quiz
          this.$emit("emitStartGpathQuiz", false, "You have already taken this assessment", "Skill Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_SKILL) && this.gpathSession.session_state < GPathData.GPATH_STATE_CAREER) {
          // User has not played the career quiz
          this.$emit("emitStartGpathQuiz", false, "Kindly complete the career assessment before attempting the next", "Career Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_APTITUDE) && this.gpathSession.session_state >= GPathData.GPATH_STATE_APTITUDE) {
          // User has already played aptitude quiz
          this.$emit("emitStartGpathQuiz", false, "You have already taken this assessment", "Aptitude Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_APTITUDE) && this.gpathSession.session_state < GPathData.GPATH_STATE_SKILL) {
          // User has not played skill quiz
          this.$emit("emitStartGpathQuiz", false, "Kindly complete the skill assessment before attempting the next", "Skill Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_COUNSELLOR) && this.gpathSession.session_state >= GPathData.GPATH_STATE_COUNSELLOR) {
          // User has already played counsellor quiz
          this.$emit("emitStartGpathQuiz", false, "You have already taken this assessment", "Extra-curricular Assessment")
          return
        }
        else if (this.propAppId.includes(GPathData.GPATH_COUNSELLOR) && this.gpathSession.session_state < GPathData.GPATH_STATE_APTITUDE) {
          // User has not played the aptitude quiz
          this.$emit("emitStartGpathQuiz", false, "Kindly complete the aptitude assessment before attempting the next", "Aptitude Assessment")
          return
        }

        this.$emit("emitStartGpathQuiz", true)
      }
      else {
        this.$emit("emitStartGpathQuiz", false, "Please make the payment before taking the assessment ", "Pending Payment")
      }

      // check whether the user has paid or not
    }
  }
}
</script>
<style>
@media (max-width: 576px) {
  .three_multi_buttons{
    margin-top: 15px;
  }
}
</style>
